import React from "react";
import about1 from "../../assets/images/about/about-three-2.png";


export default function AboutSection({ className }) {
  return (
    <section className={`about-three py-120 rpy-100 ${className || ""}`}>
      <div className="container">
        <div className="row align-items-center">
          <div className="col-lg-6">
              <img
                src={about1}
                alt="About"
              />
              
            
          </div>
          <div className="col-lg-6">
            <div className="about-content pl-70 rpl-0 wow fadeInRight delay-0-2s">
              <div className="section-title mb-35">
                <span className="sub-title">Il nostro procedimento</span>
                <h2>
                🎨Trasformiamo le Tue Idee in Progetti Professionali 

                </h2>
              </div>
              <p>
              Il nostro processo inizia con un incontro dedicato in cui il cliente condivide la sua visione. Attraverso domande esplorative, comprendiamo appieno le tue idee.
              </p>
              <ul className="list-style-three mt-15">
                <li>Soluzioni su Misura</li>
                <li>Innovazione Costante</li>
                <li>Supporto Continuo</li>
              </ul>
              <a href="/about" className="theme-btn style-three mt-25">
                Altri dettagli
              </a>
            </div>
          </div>
        </div>
      </div>
    </section>
  );
}
