import { useEffect } from "react";
import { useSelector } from "react-redux";
// Todo: for spacific page rtl disabled
// import { useLocation } from "react-router-dom";
import Routers from "./Routers";

function App() {
  const { rtl } = useSelector((state) => state.rtl);
  // const dispatch = useDispatch();
  const html = document.getElementsByTagName("html");
  // const [settingToggle, setToggle] = useToggle(false);
  // Todo: for spacific page rtl disabled
  // const location = useLocation();
  useEffect(() => {
    if (rtl) {
      html[0].dir = "rtl";
    } else {
      html[0].dir = "";
    }
    // Todo: for spacific page rtl disabled
    // if (location.pathname !== "/home-eight") {
    //   if (rtl) {
    //     html[0].dir = "rtl";
    //   } else {
    //     html[0].dir = "";
    //   }
    // }
  });

  return (
    <div className={rtl ? "direction-rtl" : ""}>
      <Routers />
      
    </div>
  );
}

export default App;
