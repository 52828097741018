import React, { useRef } from "react";
import recensione1 from "../../assets/images/testimonials/test1.png";
import recensione2 from "../../assets/images/testimonials/test2.png";
import recensione3 from "../../assets/images/testimonials/test3.png";

import backgroundImg from "../../assets/images/testimonials/testimonial-five-bg.jpg";

import SliderCom from "../helpers/SliderCom";

export default function TestimonialSection() {
  const settings = {
    infinite: true,
    autoplay: true,
    arrows: false,
    pauseOnHover: false,
    autoplaySpeed: 5000,
    slidesToShow: 1,
    slidesToScroll: 1,
    fade: true,
    cssEase: "linear",
  };
  const testimonalEightSlider = useRef(null);
  const prevHandler = () => {
    testimonalEightSlider.current.slickPrev();
  };

  const nextHandler = () => {
    testimonalEightSlider.current.slickNext();
  };
  return (
    
      <section
        className="testimonial-five pt-120 rpt-100 mb-55 relative"
        style={{ backgroundImage: `url(${backgroundImg})` }}
      >
        <div className="container">
          <div className="row">
            <div className="col-lg-7">
              <div className="testimonial-five-wrap p-70 bg-white wow fadeInRight delay-0-2s ">
                <button
                  onClick={prevHandler}
                  className="prev slick-arrow"
                  type="button"
                >
                  <i className="fas fa-chevron-left"></i>
                </button>
                <SliderCom selector={testimonalEightSlider} settings={settings}>
                  <div className="testimonial-five-item">
                    <p>
                    Sono rimasto sorpreso dalla professionalità di Digital Crafts. Hanno trasformato la mia idea in un sito web fantastico, con un design moderno e una navigazione intuitiva. La loro attenzione ai dettagli e il supporto costante durante il progetto hanno reso l'intero processo molto piacevole. Consiglio vivamente i loro servizi!
                    </p>
                    <div className="author-description">
                      <img
                        src={recensione1}
                        alt="Author"
                      />
                      <div className="name-designation">
                        <h3>Alessio Fontana</h3>
                        <span>Fontana & Co</span>
                      </div>
                    </div>
                  </div>
                  <div className="testimonial-five-item">
                    <p>
                    Digital Crafts è il partner ideale per la trasformazione digitale. Hanno creato un'app mobile che ha superato tutte le mie aspettative. Il loro approccio collaborativo e l'attenzione alla qualità del lavoro sono evidenti in ogni fase del progetto. Non potrei essere più soddisfatto del risultato finale.
                    </p>
                    <div className="author-description">
                      <img
                        src={recensione2}
                        alt="Author"
                      />
                      <div className="name-designation">
                        <h3>Elena Valente</h3>
                        <span>GreenGrove</span>
                      </div>
                    </div>
                  </div>
                  <div className="testimonial-five-item">
                    <p>
                    Ho avuto l'onore di lavorare con Digital Crafts per la consulenza digitale della mia azienda. La loro capacità di comprendere le esigenze aziendali e tradurle in soluzioni digitali è straordinaria. Grazie a loro, abbiamo ottenuto un aumento significativo nella nostra presenza online e nell'efficienza dei nostri processi aziendali. Li consiglio a chiunque cerchi un partner di fiducia per la trasformazione digitale.                    </p>
                    <div className="author-description">
                      <img
                        src={recensione3}
                        alt="Author"
                      />
                      <div className="name-designation">
                        <h3>Giulia Caruso</h3>
                        <span>TerraNova Esc</span>
                      </div>
                    </div>
                  </div>
                </SliderCom>
                <button
                  onClick={nextHandler}
                  className="next slick-arrow"
                  type="button"
                >
                  <i className="fas fa-chevron-right"></i>
                </button>
              </div>
            </div>
          </div>
        </div>
      </section>
  );
}
