import React from "react";
import aboutSixThumb from "../../assets/images/about/about-six.png";

export default function AboutSection() {
  return (
    <section className="about-section-six pb-120 rpb-100">
      <div className="container">
        <div className="row align-items-center">
          <div className="col-md-6">
            <div className="about-six-content mr-65 rmr-0 wow fadeInLeft delay-0-2s">
              <div className="section-title mb-35">
                <span className="sub-title">Il nostro staff</span>
                <h2>
                Un team di giovani artigiani del digitale
                </h2>
              </div>
              <div className="service-item-six">
                <div className="icon">
                  <i className="flaticon flaticon-3d-printing"></i>
                </div>
                <div className="service-content">
                  <h5>Passione per l'innovazione</h5>
                  
                </div>
              </div>
              <div className="service-item-six">
                <div className="icon">
                  <i className="flaticon flaticon-bars"></i>
                </div>
                <div className="service-content">
                  <h5>Soluzioni all'avanguardia</h5>
                  
                </div>
              </div>
            </div>
          </div>
          <div className="col-md-6">
            <div className="about-shape-six ml-50 rml-0 pr-30 wow fadeInRight delay-0-2s">
              <img src={aboutSixThumb} alt="About" />
            </div>
          </div>
        </div>
      </div>
    </section>
  );
}
