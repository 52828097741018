import { Link } from "react-router-dom";

function Navigation({ className }) {
  return (
    
      <div className={`restly-header-main-menu ${className || ""}`}>
        <ul>
          <li>
            <Link to="/">
              Home 
            </Link>          
          </li>
          <li>
            <Link to="/about">
              Chi siamo 
            </Link>          
          </li>
          <li>
            <Link to="/contact">Contatti</Link>
          </li>
        </ul>
      </div>
    
  );
}

export default Navigation;
