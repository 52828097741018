import React from "react";

export default function OurServices() {
  return (
    <section className="what-we-do-two text-center pt-115 rpt-95 pb-90 rpb-70">
      <div className="container">
        <div className="section-title mb-55">
          <span className="sub-title">I nostri servizi</span>
          <h2>
           Servizi in evidenza<br />
           che ti aiuteranno a costruire meglio
          </h2>
        </div>
        <div className="row justify-content-center">
          <div className="col-lg-3 col-sm-6">
            <div className="feature-item-two wow fadeInUp delay-0-2s">
              <div className="icon">
                <i className="flaticon flaticon-3d"></i>
              </div>
              <div className="feature-line">
                <span className="animate-bar"></span>
              </div>
              <h4>
                <a href="/about">Web Development</a>
              </h4>
              <p>Costruisci il futuro online con il nostro potente sviluppo web.</p>
            </div>
          </div>
          <div className="col-lg-3 col-sm-6">
            <div className="feature-item-two color-two wow fadeInUp delay-0-4s">
              <div className="icon">
                <i className="flaticon flaticon-coding"></i>
              </div>
              <div className="feature-line">
                <span className="animate-bar delay-1-0s"></span>
              </div>
              <h4>
                <a href="/about">Mobile Apps</a>
              </h4>
              <p>Porta la tua visione al mondo con app mobili innovative.</p>
            </div>
          </div>
          <div className="col-lg-3 col-sm-6">
            <div className="feature-item-two color-three wow fadeInUp delay-0-6s">
              <div className="icon">
                <i className="flaticon flaticon-art"></i>
              </div>
              <div className="feature-line">
                <span className="animate-bar delay-2-0s"></span>
              </div>
              <h4>
                <a href="/about">AI Tools</a>
              </h4>
              <p>Massimizza l'efficienza e l'innovazione aziendale con l'AI.</p>
            </div>
          </div>
          <div className="col-lg-3 col-sm-6">
            <div className="feature-item-two color-four wow fadeInUp delay-0-8s">
              <div className="icon">
                <i className="flaticon flaticon-web"></i>
              </div>
              <div className="feature-line">
                <span className="animate-bar delay-0-5s"></span>
              </div>
              <h4>
                <a href="/about">Consulenza</a>
              </h4>
              <p>Accelera la tua crescita aziendale con l'aiuto dei nostri consulenti.</p>
            </div>
          </div>
        </div>
      </div>
    </section>
  );
}
