import React from "react";
import background from "../../assets/images/contact/contact-page.jpg";
import BacktoTopCom from "../helpers/BackToTopCom";
import FooterHomeSix from "../partials/Footers/FooterHomeSix";
import Header from "../partials/Headers/Header/index";

export default function HomeSix() {
  return (
    <>
    <Header />

    <section className="contact-page py-120 rpy-100">
          <div className="container">
          <div className="contact-info-area mb-80">
              <div className="contact-info-item wow fadeInUp delay-0-2s">
                <i className="far fa-map"></i>
                <p>Firenze, Italia</p>
              </div>
              <div className="contact-info-item wow fadeInUp delay-0-4s">
                <i className="far fa-envelope"></i>
                <p>
                  <a href="mailto:info@digitalcrafts.it">info@digitalcrafts.it</a>{" "}
                  <br />
                  <a href="www.digitalcrafts.it">www.digitalcrafts.it</a>
                </p>
              </div>
              <div className="contact-info-item wow fadeInUp delay-0-6s">
                <i className="fas fa-phone-alt"></i>
                <p>
                  <a href="callto:+393913930104">+39 391 393 0104</a> <br />
                  <a href="callto:+393395094575">+39 339 509 4575</a>
                </p>
              </div>
            </div>
            <div className="row">
              <div className="col-lg-4">
                <div
                  className="contact-form-left bgs-cover h-100 wow fadeInLeft delay-0-2s"
                  style={{ backgroundImage: `url(${background})` }}
                >
                  <h2>Facci sapere di cosa hai bisogno</h2>
                </div>
              </div>
              <div className="col-lg-8">
                <div className="contact-form ml-40 rml-0 rmt-55 wow fadeInRight delay-0-2s">
                  <h3 className="comment-title mb-35">Inviaci un messaggio</h3>
                  <p>
                  Siamo disponibili per rispondere a qualsiasi tipo di interrogativo, che si tratti di informazioni sui prodotti, assistenza tecnica o semplici curiosità. Non esitare a compilare il modulo di contatto qui sotto, oppure troverai anche i nostri recapiti telefonici e indirizzo email nel caso preferissi comunicare in quel modo. La tua comunicazione è importante e faremo del nostro meglio per rispondere nel minor tempo possibile.
                  </p>
                  <form
                    id="comment-form"
                    className="comment-form mt-35"
                    name="comment-form"
                    action="#"
                    method="post"
                  >
                    <div className="row clearfix justify-content-center">
                      <div className="col-sm-6">
                        <div className="form-group">
                          <label htmlFor="full-name">
                            <i className="far fa-user"></i>
                          </label>
                          <input
                            type="text"
                            id="full-name"
                            name="full-name"
                            className="form-control"
                            value=""
                            placeholder="Nome completo"
                            required=""
                          />
                        </div>
                      </div>
                      <div className="col-sm-6">
                        <div className="form-group">
                          <label htmlFor="email">
                            <i className="far fa-envelope"></i>
                          </label>
                          <input
                            type="email"
                            id="email"
                            name="email"
                            className="form-control"
                            value=""
                            placeholder="La tua mail"
                            required=""
                          />
                        </div>
                      </div>
                      <div className="col-sm-12">
                        <div className="form-group">
                          <label htmlFor="comments">
                            <i className="fas fa-pencil-alt"></i>
                          </label>
                          <textarea
                            name="comments"
                            id="comments"
                            className="form-control"
                            rows="4"
                            placeholder="Il tuo messaggio"
                            required=""
                          ></textarea>
                        </div>
                      </div>
                      <div className="col-sm-12">
                        <div className="form-group mb-0">
                          <button type="submit" className="theme-btn">
                            Invia mail
                          </button>
                        </div>
                      </div>
                    </div>
                  </form>
                </div>
              </div>
            </div>
          </div>
    </section>
    <div className="contact-page-map">
          <div className="our-location">
            <iframe
              title="map"
              src="https://www.google.com/maps/embed?pb=!1m18!1m12!1m3!1d3587.573796815398!2d11.272923669489957!3d43.76362650662497!2m3!1f0!2f0!3f0!3m2!1i1024!2i768!4f13.1!3m3!1m2!1s0x132a53d361e8c4d3%3A0xb2782141366ad11e!2sFirenze%20sud!5e1!3m2!1sit!2sit!4v1694710904780!5m2!1sit!2sit"
              height="650"
              style={{ border: "0", width: "100%" }}
              allowFullScreen=""
              loading="lazy"
            ></iframe>
          </div>
    </div>
    <FooterHomeSix />
    <BacktoTopCom />
    </>
    );
}
      