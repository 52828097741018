import React from "react";
import logo from "../../../../assets/images/logos/logo-two.png";

export default function FooterHomeSix() {
  return (
    <footer className="main-footer footer-five bg-lighter pt-110">
      <div className="container">
        <div className="row">
          <div className="col-md-4 col-sm-6">
            <div className="footer-widget about-widget">
              <div className="footer-logo mb-35">
                <a href="/">
                  <img src={logo} alt="Logo" />
                </a>
              </div>
              <div className="text">
              Un'idea diventa realtà! Scopriamo insieme il percorso dietro ogni progetto di successo              </div>
              <div className="social-style-two mt-30">
                <a href="http://facebook.com">
                  <i className="fab fa-facebook-f"></i>
                </a>
                <a href="http://twitter.com">
                  <i className="fab fa-twitter"></i>
                </a>
                <a href="https://www.instagram.com/digitalcrafts.it/">
                  <i className="fab fa-instagram"></i>
                </a>             
              </div>
            </div>
          </div>
          {/* <div className="col-md-3 col-sm-6">
            <div className="footer-widget link-widget ml-50 rml-0">
              <h4 className="footer-title">Product</h4>
              <ul className="list-style-two">
                <li>
                  <a href="#">Landing page</a>
                </li>
                <li>
                  <a href="#">Features</a>
                </li>
                <li>
                  <a href="#">Documentation</a>
                </li>
                <li>
                  <a href="#">Referral Program</a>
                </li>
                <li>
                  <a href="#">Pricing</a>
                </li>
              </ul>
            </div>
          </div> */}
          <div className="col-md-4 col-sm-6">
            <div className="footer-widget link-widget ml-50 rml-0">
              <h4 className="footer-title">Servizi</h4>
              <ul className="list-style-two">
                <li>
                  <a href="#">Documentazioni</a>
                </li>
                <li>
                  <a href="#">Licenza</a>
                </li>
                <li>
                  <a href="#">Norme privacy</a>
                </li>
                <li>
                  <a href="#">Contatti</a>
                </li>
              </ul>
            </div>
          </div>
          <div className="col-md-4 col-sm-6">
            <div className="footer-widget contact-widget mr-30 rmr-0">
              <h4 className="footer-title">Informazioni</h4>
              <ul className="list-style-two">
                <li>
                  <i className="fas fa-phone-alt"></i>{" "}
                  <a href="callto:+393913930104">+39 391 393 0104</a>
                </li>
                <li>
                  <i className="fas fa-phone-alt"></i>{" "}
                  <a href="callto:+393395094575">+39 339 509 4575</a>
                </li>
                <li>
                  <i className="fas fa-envelope"></i>{" "}
                  <a href="mailto:info@digitalcrafts.it">info@digitalcrafts.it</a>
                </li>
                
              </ul>
            </div>
          </div>
        </div>
      </div>
      <div className="copyright-area mt-50">
        <div className="container">
          <div className="copyright-inner justify-content-center">
            <p>@2023 Digital Crafts All Rights Reserved.</p>
          </div>
        </div>
      </div>
    </footer>
  );
}
