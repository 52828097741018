import React from "react";
import background from "../../../assets/images/banner.jpg";

export default function Hero() {
  return (
    <section
      className="page-banner bgs-cover  pt-50"
      style={{ backgroundImage: `url(${background})` }}
    >
      <div className="container">
        <div className="banner-inner">
          
        </div>
      </div>
    </section>
  );
}
