import React from "react";
import BacktoTopCom from "../helpers/BackToTopCom";
import FooterHomeSix from "../partials/Footers/FooterHomeSix";
import Header from "../partials/Headers/Header/index";
import AboutSection from "./AboutSection";
import CallToActionSection from "./CallToActionSection";
import Hero from "./Hero";
import OurServices from "./OurServices";

export default function HomeSix() {
  return (
    <>
      <Header />
      <Hero />
      <OurServices />
      <AboutSection />
      <CallToActionSection />
      {/* <Sponsers /> */}
      {/* <CustomizeSection /> */}
      {/* <TeamSection teams={teams} /> */}
      {/* <PricingSectionFive /> */}
      {/* <CounterSection /> */}
      {/* <TestimonialSection /> */}
      {/* <SubscribeSection /> */}
      <FooterHomeSix />
      <BacktoTopCom />
    </>
  );
}
