import React from "react";
import FooterHomeSix from "../../partials/Footers/FooterHomeSix";
import Header from "../../partials/Headers/Header";
import Hero from "./Hero";

export default function Layouts({ children, pageTitle, breadcrumbs = [] }) {
  return (
    <>
      <Header />
      <Hero pageTitle={pageTitle} breadcrumbs={breadcrumbs} />
      {children && children}
      <FooterHomeSix />
    </>
  );
}
