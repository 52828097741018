import React from "react";
import Layouts from "../helpers/Layouts";
import AboutSection from "./AboutSection";
import CallToActionSection from "./CallToActionSection";
import TestimonialSection from "./TestimonialSection";
import WorkProcessSection from "./WorkProcessSection";

export default function AboutOne() {
  return (
    
      <Layouts
        pageTitle="Chi siamo"
        breadcrumbs={[
          { name: "home", path: "/" },
          { name: "about", path: "/about-one" },
        ]}
      >

        <WorkProcessSection />
        <AboutSection className="pb-240" />

        <TestimonialSection /> 
        <CallToActionSection />

      </Layouts>
    
  );
}
